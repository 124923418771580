import { createGlobalStyle } from 'styled-components';
import bg from '../assets/images/bg_winter.jpg';
import fox from '../assets/images/fox.png';
import snow from '../assets/images/snow.png';
import stripes from '../assets/images/stripes.svg';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --primary: #3ac979;
    --secondary: #ebb891;
    --complement: #f96e95;
    --analogous: #6edbf9;
  } 
  html {
    background-color: #f0f3ff;
    background-image: url("https://www.transparenttextures.com/patterns/cardboard.png");
    background-repeat: repeat;
    font-size: 120%;
    @media (min-width: 500px) {
      background-position: bottom -50px left, bottom left -100px, bottom right -100px, bottom left;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: 0px, 100%,  100%, cover;
      background-image: url(${fox}), url(${snow}),url(${snow}), url(${bg});
      background-size: 300px, 100%,  100%, cover;
      background-attachment: fixed, fixed, fixed, fixed;
    }
  }

  body {
    font-size: 2rem;
  }

  .main {
    margin: 0 auto;
    padding-left: 0rem;
    padding-right: 0rem;
    max-width: 900px;
    @media (min-width: 500px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--secondary);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--secondary) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    /* border: 0; */
    /* height: 8px; */
    /* background-image: url(${stripes}); */
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

`;

export default GlobalStyles;
