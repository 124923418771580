import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  text-align: right;
  margin-top: 6rem;
  a {
    color: #000;
  }
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      display: inline;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <p>
        <br />
        <br />
        &copy; {new Date().getFullYear()} Wurzelkinder-Waldkindergarten
        Pleidelsheim e.V.
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.wurzelkinder-pleidelsheim.de/impressum"
          >
            Impressum
          </a>
        </li>
        <li>
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
      </ul>
    </FooterStyles>
  );
}
