import { createGlobalStyle } from 'styled-components';

import drina from '../assets/fonts/drina.otf';
import brushstrokeGreen from '../assets/images/brushstroke-green.png';

const Typography = createGlobalStyle` 
  @font-face { 
    font-family: Drina;
    src: url(${drina}) format("opentype");
  }
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/open-sans-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* open-sans-600 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
        url('/fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/open-sans-v18-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  html {
    font-family: Drina, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
    font-size: 16px;
  }

  legend {
    font-family: Drina !important;
    font-size: 2rem !important;
  }

  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  h1 {
    font-size: 3rem;
    @media (min-width: 500px) {
      font-size: 4rem;
    }
    @media (min-width: 1000px) {
      font-size: 5rem;
    }
  }
  h2 {
    font-size: 2rem;
    @media (min-width: 500px) {
      font-size: 2.5rem;
    }
    @media (min-width: 1000px) {
      font-size: 3.5rem;
    }
  }
  h3 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    @media (min-width: 500px) {
      font-size: 2rem;
    }  
    @media (min-width: 1000px) {
      font-size: 2rem;
    }  
  }
  h4 {
    font-family: "Open Sans";
    font-size: 1.5rem;
  }
  a {
    text-decoration:none;
    color: var(--black);
    &:hover, &:active, &:focus {
      color: var(--black);
    }
  }
  .textlink {
    text-decoration:none;
    border-bottom: 3px solid var(--primary);
    color: black;
    &:hover, &:active, &:focus {
      color: black;
    }
  }
  .bigFont {
    font-size: 150%;
  }
  footer {
    font-family: "Open Sans";
    font-size: 1rem;
  }
  mark, .mark {
    background-image: url(${brushstrokeGreen});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 3rem;
    margin: 0;
    line-height: 1;
    color: var(--white);
    &:hover, &:active, &:focus {
      color: var(--white);
    }
  }

  .drina {
        font-family: Drina, sans-serif;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
