import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Logo from './Logo';

const NavStyles = styled.nav`
  margin-bottom: 4rem;
  /* padding-left: 1rem;
  padding-right: 1rem; */
  @media (min-width: 1000px) {
    margin-top: 5rem;
  }
  .start {
    display: none;
    @media (min-width: 500px) {
      display: block;
    }
  }
  .logo-mobile {
    display: block;
    @media (min-width: 500px) {
      display: none;
    }
  }
  .logo {
    display: none;
    @media (min-width: 500px) {
      display: block;
      img {
        display: block;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
      grid-gap: 2rem;
    }
    align-items: center;
    @media (min-width: 1000px) {
      margin-top: -10rem;
    }
  }
  li {
    --rotate: -2deg;
    transform: rotate(var(--rotate));
    order: 1;
    &:nth-child(1) {
      --rotate: 1deg;
    }
    &:nth-child(2) {
      --rotate: -2.5deg;
      @media (min-width: 500px) {
        text-align: left;
      }
    }
    &:nth-child(4) {
      --rotate: 2.5deg;
    }
    &:nth-child(5) {
      @media (min-width: 500px) {
        text-align: left;
      }
    }
    &:nth-child(6) {
      @media (min-width: 500px) {
        text-align: right;
      }
    }
    /* &:hover {
      --rotate: 3deg;
    } */
  }
  a {
    font-size: 2rem;
    @media (min-width: 700px) {
      font-size: 2.5rem;
    }
    @media (min-width: 1000px) {
      font-size: 3.5rem;
    }

    text-decoration: none;
    &:hover {
      color: var(--primary);
    }
    &[aria-current='page'] {
      color: var(--primary);
    }
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <li className="logo-mobile">
          <Link to="/">
            <Logo />
          </Link>
        </li>
        <li className="start">
          <Link to="/">Start</Link>
        </li>
        <li>
          <Link to="/kraenze">Kränze</Link>
        </li>
        <li className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </li>
    {/*}    <li>
          <Link to="/wurzelkinder">Wir</Link>
  </li> */}
        <li>
          <Link to="/bestellung">Bestellung</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
