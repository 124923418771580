import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const SiteBorderStyles = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  @media (min-width: 500px) {
    background: rgba(255, 255, 255, 0.5);
  }
  @media (min-width: 1000px) {
    border-radius: 10px;
    margin: auto auto 4rem auto;
    margin-top: clamp(2rem, 6vw, 8rem);
    background: transparent;
    padding: 3.5rem 3.5rem 1rem 3.5rem;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteBorderStyles>
        <Nav />
        {children}
        <Footer />
      </SiteBorderStyles>
    </>
  );
}
